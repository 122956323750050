/*-------------------
     Breakpoints, copied from original /theme.
--------------------*/
$mobileBreakpoint: 320px;
$tabletBreakpoint: 768px;
$computerBreakpoint: 992px;
$largeMonitorBreakpoint: 1200px;
$largeMonitorBreakpointxl: 1300px;
$widescreenMonitorBreakpoint: 1920px;

/* Set fonts */
$header-font: 'Aleo';
$page-font: 'Lato';

/* Set narrow same as default */
$narrow-container-width: 1111px;
$default-container-width: 1111px;
