.GridCardListing {
  .listing-item {
    padding: 30px 20px;
    border: 1px solid;
    border-radius: 25px;
    transition: background-color 0.25s;

    &:hover {
      //background-color: rgba(233, 233, 233, 0.511);
      background-color: #6bc3ef;
      background-image: linear-gradient(
        to right,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 0) 40%,
        rgba(255, 255, 255, 0.7) 100%
      );
      background-repeat: no-repeat;
      background-size: 200% 100%;
      transition:
        background-size 1s,
        background-color 1s;
    }

    a {
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  &.items .listing-body {
    width: 70%;

    p {
      display: -webkit-box; /* Use the -webkit-box display style for compatibility */
      overflow: hidden;
      height: 53px;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2; /* Limit the content to two lines */
      text-overflow: ellipsis;
    }
  }
}

.mediapage-summary-template {
  min-height: 100px;

  .mediapage-item {
    display: grid;
    grid-template-columns: auto auto;
    width: 100%;
    justify-content: left;

    .mediapage-img-wrapper {
      width: 320px;
      height: 180px;
      margin-right: 20px;

      .mediapage-preview-img {
        width: 100%;
        height: 180px;
        border-radius: 12px;
        aspect-ratio: auto 320 / 180;
        object-fit: cover;
      }
    }
  }
}

@media only screen and (max-width: $computerBreakpoint) {
  .mediapage-summary-template {
    width: 100%;

    .mediapage-item {
      grid-template-columns: 100%;
      justify-content: center;
      width: 100%;
    }
  }
}

@media only screen and (max-width: $tabletBreakpoint) {
  .mediapage-summary-template {
    .mediapage-item {
      .mediapage-img-wrapper {
        margin: auto;
      }

      .listing-body {
        margin: auto;
        width: 320px;
      }
    }
  }
}
