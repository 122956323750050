#page-document,
#page-add,
#page-edit {
  .block.search,
  .block.listing {
    .facets.right {
      margin-top: 0;
      margin-left: 17px;
    }
    .facets.left {
      margin-top: 0;
    }

    .search-wrapper {
      width: 100%;
    }

    .listing-item {
      h3 {
        color: #205c90;
        font-size: 24px;
        font-weight: 500;
      }

      a:hover {
        text-decoration: underline;
      }
    }
  }
}

@media only screen and (max-width: $largeMonitorBreakpoint) {
  #page-document,
  #page-add,
  #page-edit {
    .block.search,
    .block.listing {
      .facets.left {
        margin-left: 20px;
      }
    }
  }
}

.tree-branch {
  margin-left: 9px;
  position: relative;
}

.tree-branch.closed {
  display: none;
}

.tree-branch-header {
  display: flex;
  margin-top: 8px;
}

.tree-branch-header .icon {
  margin-left: -17px;
  flex-shrink: 0;
}
